import { CardProps } from '@chakra-ui/card';
import CardWithLinks from '@components/CardWithLinks';
import Container from '@components/LayoutStructure/Container';
import { CatalogLink } from '@components/NearbyCare/types';
import { getColor } from '@utils/getColor';
import { createID, toCapitalizedWords } from '@utils/strings';
import { getStateAbbreviation } from '@utils/UsStates';
import { useContext } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingSizes } from '~/@types/heading';
import SiteContext from '~/contexts/SiteContext';

type InterlinkingModuleData = {
  text: string;
  href: string;
};

enum Breakpoint {
  base = 'base',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

enum LineHeight {
  base = '1.5rem', // 0px
  xs = '1.75rem', // 4px
  sm = '2rem', // 8px
  md = '2.25rem', // 12px
  lg = '2.5rem', // 16px
  xl = '2.75rem', // 20px
  '2xl' = '3rem', // 24px
}

export type InterlinkingModuleProps = {
  title: string;
  titleSize: HeadingSizes;
  noHorizontalPadding: boolean;
  columns: string;
  responsiveColumns?: {
    [key in Breakpoint]?: string;
  };
  bgColor: string;
  bgColorRange: string;
  titleColor: string;
  titleColorRange: string;
  linksColor: string;
  linksColorRange: string;
  linksTextDecoration: string;
  jsonData?: string;
  data?: {
    [key: string]: string[];
  };
  switchable: {
    nearbyType?: string;
    state?: string;
    city?: string;
    county?: string;
    careType?: string | { name: string };
    field?: 'catalogData' | 'jsonData';
    jsonData?: string;
    excludeCareTypes: {
      'continuing-care': boolean;
      'assisted-living': boolean;
      'memory-care': boolean;
      'retirement-communities': boolean;
      'independent-living': boolean;
      'nursing-homes': boolean;
    };
  };
  card?: {
    size?: CardProps['size'];
    variant?: CardProps['variant'];
  };
  list?: {
    spacing?: keyof LineHeight;
  };
};

const buildLinks = (catalogLinks, excludeCareTypes) => {
  const links: Array<CatalogLink> = [];
  if (catalogLinks['state']) {
    catalogLinks['state'].forEach((v) => {
      if (!excludeCareTypes[v.careType]) {
        links.push({
          text: `${toCapitalizedWords(v.state)} ${toCapitalizedWords(
            v.careType
          )}`,
          href: `${v.urlPath}/`,
        });
      }
    });
  } else if (catalogLinks['city']) {
    catalogLinks['city'].forEach((v) => {
      if (!excludeCareTypes[v.careType]) {
        links.push({
          text: `${toCapitalizedWords(v.careType)} in ${toCapitalizedWords(
            v.city
          )}, ${getStateAbbreviation(v.state)}`,
          href: `${v.urlPath}/`,
        });
      }
    });
  } else if (catalogLinks['county']) {
    catalogLinks['county'].forEach((v) => {
      if (!excludeCareTypes[v.careType]) {
        links.push({
          text: `${toCapitalizedWords(v.careType)} in ${toCapitalizedWords(
            v.county
          )}, ${getStateAbbreviation(v.state)}`,
          href: `${v.urlPath}/`,
        });
      }
    });
  }
  return links;
};

const InterlinkingModule = ({
  title,
  titleSize,
  noHorizontalPadding = false,
  columns = '1',
  switchable,
  data,
  jsonData = '',
  bgColor,
  bgColorRange,
  titleColor = 'primary',
  titleColorRange = '700',
  linksColor = 'link',
  linksColorRange = '600',
  linksTextDecoration = 'underline',
  responsiveColumns,
  card,
  list,
}: InterlinkingModuleProps): React.ReactElement => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const backgroundColor =
    bgColor === 'white' || bgColor === 'black'
      ? bgColor
      : bgColor === 'none'
      ? ''
      : `${bgColor}.${bgColorRange}`;
  let parsedJson: { data: Array<InterlinkingModuleData> } | null = null;

  let catalogLinks: CatalogLink[] = [];

  if (data && Object.keys(data).length > 0) {
    catalogLinks = buildLinks(data, switchable.excludeCareTypes);
  }

  const responsive = {};

  if (responsiveColumns) {
    Object.keys(responsiveColumns).forEach((key) => {
      if ((Object.values(Breakpoint) as string[]).includes(key)) {
        responsive[key] = responsiveColumns[key]
          ? parseInt(responsiveColumns[key])
          : undefined;
      }
    });
  }

  // TODO: jsonData will be deprecated, this needs to be removed when all components are switched over to switchable.jsonData
  if (jsonData) {
    try {
      parsedJson = JSON.parse(jsonData);
      if (!Array.isArray(parsedJson?.data)) {
        console.error('Invalid jsonData: provided JSON data is not an array');
      }
    } catch {
      console.error(
        'Invalid jsonData: please check your data is a properly formatted JSON Array'
      );
    }
  } else if (switchable?.field === 'jsonData' && switchable?.jsonData) {
    try {
      parsedJson = JSON.parse(switchable.jsonData);
      if (!Array.isArray(parsedJson?.data)) {
        console.error('Invalid jsonData: provided JSON data is not an array');
      }
    } catch {
      console.error(
        'Invalid jsonData: please check your data is a properly formatted JSON Array'
      );
    }
  }

  return (parsedJson && parsedJson.data.length > 0) ||
    (catalogLinks && catalogLinks.length > 0) ? (
    <Container px={{ base: 0, md: noHorizontalPadding ? 0 : 8 }}>
      <CardWithLinks
        cardProps={{
          size: card?.size,
          variant: card?.variant,
          backgroundColor: backgroundColor,
        }}
        headingProps={{
          id: createID(title),
          color: getColor(titleColor, titleColorRange),
          size: titleSize,
          scrollMarginTop: 12,
        }}
        listProps={{
          spacing: undefined,
          sx: {
            columns: responsiveColumns
              ? { ...responsive }
              : { base: 1, md: parseInt(columns) },
          },
        }}
        listItemProps={{
          lineHeight: list?.spacing ? LineHeight[list.spacing] : LineHeight.sm,
        }}
        linkProps={{
          color: getColor(linksColor, linksColorRange),
          fontSize: 'md',
          textDecoration: linksTextDecoration,
        }}
        heading={title}
        headingElement="h4"
        links={(switchable?.field === 'catalogData'
          ? catalogLinks
          : parsedJson?.data || []
        ).map((link) => ({
          text: link.text,
          url: link.href,
        }))}
      />
    </Container>
  ) : (
    <></>
  );
};

export default withHydrationOnDemand({ on: ['visible'] })(InterlinkingModule);
